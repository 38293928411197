import React, { FC, useEffect, useMemo } from "react";
import { CSSTransition } from "react-transition-group";
import FooterContainer from "@containers/FooterContainer/FooterContainer";
import FoodProfileChartContainer from "@containers/FoodProfileChartContainer/FoodProfileChartContainer";
import RegionalPenetration from "@containers/RegionalPenetrationContainer/RegionalPenetration";
import Page from "@components/Page/Page";
import Title from "@components/Title/Title";
import Loader from "@components/Loader/Loader";
import HorizontalBarChart from "@components/HorizontalBarChart/HorizontalBarChart";
import RestaurantActivityList from "@components/RestaurantActivityList/RestaurantActivityList";
import CircularProgressbarWidthContent
    , {
    iCircularProgressbarWidthContentData
} from "@components/CircularProgressbarWidthContent/CircularProgressbarWidthContent";
import useQueryParam from "@hooks/useQueryParam";
import useFoodProfile from "./useFoodProfile";
import FoodProfileFooter from "./FoodProfileFooter/FoodProfileFooter";
import "./FoodProfile.styles.scss";
import FoodProfileTable from "./FoodProfileTable/FoodProfileTable";
import useFoodProfileLTO from "@apiHooks/useFoodProfileLTO";
import { useSelector } from "react-redux";
import { currentCountrySelector } from "@selectors/countries.selector";
import { equalInLC } from "@core/old_helpers";
import { useLocation, useNavigate } from "react-router-dom";
import FoodProfileFlavor from "@pages/FoodProfile/FoodProfileFlavor/FoodProfileFlavor";
import { getMacStageName } from "@selectors/byo.selectors";
import cn from "classnames";
import {
    AppId,
    ProductLogo,
    sanitize,
    usePlatformContext,
    usePlatformFeatureFlag
} from "@datassential/platform-ui-lib";
import { FEATURE_LOCAL_LINK } from "@constants/general";


const macStageArr = [
    'Inception',
    'Adoption',
    'Proliferation',
    'Ubiquity'
];

const FoodProfile: FC = () => {
    const isLocalRedirectEnabled = usePlatformFeatureFlag(FEATURE_LOCAL_LINK);
    const { userInfo: { products } } = usePlatformContext();

    const isLocalProductEnabled = useMemo(
        () => {
            return !products[AppId.LocalInsights]?.isDisabled;
        },
        [products]
    );

    const localProductLink = useMemo(
        () => {
            return products[AppId.LocalInsights]?.url;
        },
        [products]
    );

    const location = useLocation();
    const history = useNavigate();
    const localName = useQueryParam("name") || '';
    const id = useQueryParam("id");
    const countryCode = useSelector(currentCountrySelector);

    const {
        isLoading,
        foodProfile,
        currentChart,
        handleExportProfile,
        barCharts,
    } = useFoodProfile({ id, name: localName });

    const {
        data: ltos,
    } = useFoodProfileLTO({ id, countryCode });

    const {
        name: apiName,
        penetration,
        oneYearGrowth,
        fourYearsGrowth,
        fourYearsPredictedGrowth,
        totalGrowth,
        regionsPenetrations,
        yearsPenetrations,
        venueTypesValues,
        filterDescription,
        macStage,
        years,
        flavorData,
        isLocal,
    } = foodProfile;

    const name = apiName || localName;

    const footerRightContent = useMemo(() => (
        <FoodProfileFooter onClick={handleExportProfile}/>
    ), [handleExportProfile]);

    const circularProgressbarData = useMemo((): iCircularProgressbarWidthContentData => ({
        penetration,
        oneYearGrowth,
        fourYearsGrowth,
        fourYearsPredictedGrowth,
        totalGrowth,
        filterDescription,
        macStage,
    }), [filterDescription, fourYearsGrowth, fourYearsPredictedGrowth, macStage, oneYearGrowth, penetration, totalGrowth]);

    useEffect(() => {
        if (apiName !== '' && !equalInLC(apiName, localName)) {
            history({
                ...location,
                search: location.search.replace(
                    new RegExp(`name=${localName}`, 'gi'),
                    `name=${apiName}`,
                ),
            });
        }
    }, [apiName, localName, history, location]);

    return (
        <Page
            isWrapped
            title="Food Profile"
        >
            <div className="FoodProfile">
                <div className="FoodProfile__header">
                    <Title>
                        <div className="FoodProfile__menu-title">{name}</div>
                    </Title>
                    {macStage > 0 && (
                        <div className="FoodProfile__mac-stage-container">
                            <div className="FoodProfile__mac-stage-list">
                                {macStageArr.map((el, idx) => (
                                    <div
                                        key={idx}
                                        className={cn(
                                            "FoodProfile__mac-stage-title",
                                            `FoodProfile__mac-stage-title-${el}`,
                                        )}>
                                        {el}
                                    </div>
                                ))}
                                {macStageArr.map((el, idx) => (
                                    <div
                                        className={cn(
                                            "FoodProfile__mac-stage",
                                            `FoodProfile__mac-stage-${el}`,
                                            getMacStageName(macStage) === el && 'FoodProfile__mac-stage--active',
                                        )}
                                        key={idx}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <Loader isShown={isLoading} noBg/>

                <div className="FoodProfile__menu-penetration">
                    <div className="FoodProfile__wrap">
                        <div className="FoodProfile__chart">
                            {currentChart && (
                                <CSSTransition
                                    in={!isLoading}
                                    classNames="appears-xx-3"
                                    timeout={3000}
                                    unmountOnExit
                                >
                                    <FoodProfileChartContainer data={currentChart}/>
                                </CSSTransition>
                            )}
                        </div>
                        <CSSTransition
                            in={!isLoading}
                            classNames="appears-x-3"
                            timeout={3000}
                            unmountOnExit
                        >
                            <CircularProgressbarWidthContent data={circularProgressbarData}/>
                        </CSSTransition>
                    </div>
                </div>

                <div className="FoodProfile__content">
                    <CSSTransition in={!isLoading} classNames="appears3" timeout={3000} unmountOnExit>
                        <div className="FoodProfile__bg-wrapper">
                            <div>
                                <Title>
                                    <div className="FoodProfile__title">
                                        regional penetration
                                        {isLocalProductEnabled && isLocalRedirectEnabled && isLocal && (
                                            <div className="FoodProfile__sub-title">
                                                for a heat map of ”{name}” by market, go to
                                                <a
                                                    href={sanitize(`${localProductLink}/${process.env.REACT_APP_LOCAL_KEYWORD_LINK}/${id}`)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <ProductLogo
                                                        appId={AppId.LocalInsights}
                                                        type="regular"
                                                        style={{
                                                            width: 16,
                                                            height: 16,
                                                            marginLeft: 6,
                                                        }}
                                                    />
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </Title>
                                <RegionalPenetration data={regionsPenetrations}/>
                            </div>

                            <div className="FoodProfile__table">
                                <Title>
                                    <div className="FoodProfile__title">venue types</div>
                                </Title>
                                <FoodProfileTable
                                    data={venueTypesValues}
                                    yearsPenetrations={[...yearsPenetrations, ...foodProfile.chart.actualDataQuarters]}
                                    fourYearsGrowth={fourYearsGrowth}
                                    years={years}
                                />
                            </div>

                            <div className='FoodProfile__horizontalBarCharts-container'>
                                {barCharts.map(({ title, background, data }) => {
                                    return (
                                        <HorizontalBarChart
                                            key={title}
                                            title={title}
                                            data={data}
                                            background={background}
                                        />
                                    )
                                })}
                            </div>

                            {flavorData.hasData && (
                                <FoodProfileFlavor
                                    keywordName={name}
                                    data={flavorData}
                                    currentCountry={countryCode}
                                />
                            )}

                            {ltos.length > 0 && (
                                <div className="FoodProfile__restaurant-activity">
                                    <Title>
                                        <div className="FoodProfile__title">
                                            RECENT ACTIVITY AT MAJOR CHAINS
                                        </div>
                                    </Title>
                                    <RestaurantActivityList data={ltos}/>
                                </div>
                            )}
                        </div>
                    </CSSTransition>

                </div>
            </div>

            <FooterContainer footerRightContent={footerRightContent}/>
        </Page>
    )
}


export default FoodProfile;