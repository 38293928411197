import { FCX } from "@datassential/platform-ui-lib";
import cn from "classnames";

const Title: FCX = ({
    children,
    className,
    style,
    testId,
}) => {
    return (
        <div
            className={cn("title-container", className)}
            style={style}
            data-testid={testId}
        >
            {children}
        </div>

    )
};
export default Title;